
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import SwiperContainer from '~/components/Containers/SwiperContainer.vue'
import { EcosystemDropdownMenuData, EcosystemMenuItem, EcosystemMenuItemUIKitFormat } from '~/model/EcosystemMenuItem'
import { isBoolean } from '~/shared/utils/guards'

interface SliderOptions {
  loop?: boolean
  slidesPerView?: number
  slidesPerGroup?: number
  spaceBetween?: number
  navigationId?: string
  pagination?: boolean
}

@Component({
  components: {
    SwiperContainer,
    AppLazyImage
  }
})
export default class HeaderEcosystemMobile extends Vue {
  @Prop({
    default: false
  })
  isVisible!: boolean

  touchStartY: number = 0

  public dropdownContent: EcosystemDropdownMenuData = {
    title: 'Экосистема',
    description: 'Технологии для развития сельского хозяйства в едином цифровом пространстве',
    anotherServices: {
      title: 'другие площадки экосистемы',
      description: '',
      content: []
    },
    popularServices: {
      title: 'популярное на я в агро',
      description: '',
      content: []
    }
  }

  public readonly SLIDER_OPTIONS: SliderOptions = {
    spaceBetween: 8
  }

  @Watch('isVisible')
  async onIsVisibleChange(visible: boolean) {
    if (!isBoolean(visible)) {
      return
    }

    if (visible) {
      const [otherServicesList, popularServicesList] = await Promise.all([
        this.$services.ecosystem.getOtherServicesMenuList<EcosystemMenuItem[]>(),
        this.$services.ecosystem.getPopularServicesMenuList<EcosystemMenuItem[]>()
      ])
      this.setDropdownContent(otherServicesList, popularServicesList)
    }

    const el = this.$refs.touchedEcosystem as HTMLElement
    if (visible) {
      el.addEventListener('touchstart', this.setTouchStart)
      el.addEventListener('touchmove', this.detectSwipeDown)
      document.addEventListener('click', this.clickWithout)

      this.toggleScroll('hidden')
    } else {
      el.removeEventListener('touchstart', this.setTouchStart)
      el.removeEventListener('touchmove', this.detectSwipeDown)
      document.removeEventListener('click', this.clickWithout)

      this.toggleScroll('auto')
    }
  }

  public openService(link: string, params = { newTab: true }): void {
    const linkElement = document.createElement('a')
    linkElement.href = link
    if (params.newTab) {
      linkElement.target = '_blank'
    }
    document.body.insertAdjacentElement('beforeend', linkElement)
    linkElement.click()
    document.body.removeChild(linkElement)
  }

  protected setDropdownContent(otherServicesList: EcosystemMenuItem[], popularServicesList: EcosystemMenuItem[]): void {
    this.dropdownContent = {
      ...this.dropdownContent,
      anotherServices: {
        ...this.dropdownContent.anotherServices,
        content: otherServicesList.map((item: EcosystemMenuItem) => this.transformListItemForUIKit(item))
      },
      popularServices: {
        ...this.dropdownContent.popularServices,
        content: popularServicesList.map((item: EcosystemMenuItem) =>
          this.transformListItemForUIKit(item, { withContent: false })
        )
      }
    }
  }

  protected transformListItemForUIKit(
    item: EcosystemMenuItem,
    params = { withContent: true }
  ): EcosystemMenuItemUIKitFormat {
    return {
      id: item.id,
      link: item.link,
      name: item.name,
      image_1: this.$services.image.cropImage(item.image, {
        mode: 'auto'
      }),
      content: params.withContent ? item.content : ''
    }
  }

  public closeEcosystem(): void {
    this.$emit('on-close')
  }

  clickWithout({ target }): void {
    const elem = this.$refs.ecosystems as HTMLElement
    const li = target.closest('li') ? target.closest('li').classList.contains('tab') : false
    if (elem && target !== elem && !elem.contains(target) && !li) {
      this.closeEcosystem()
    }
  }

  setTouchStart(e): void {
    this.touchStartY = e.changedTouches[0].screenY
  }

  detectSwipeDown(e): void {
    const touchendY = e.changedTouches[0].screenY

    if (touchendY > this.touchStartY) {
      const el = this.$refs.touchedEcosystem as HTMLElement
      el.removeEventListener('touchmove', this.detectSwipeDown)
      this.closeEcosystem()
    }
  }

  toggleScroll(value: 'hidden' | 'auto'): void {
    if (process.browser) {
      window.document.body.style.overflow = value
    }
  }
}
